<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
  >
    <b-row>
      <b-col>
        <div class="form-group mb-0">
          <div class="input-eye">
            <p class="tag-eye"><EyeFill /></p>
            <input
              autocomplete="off"
              id="avb"
              :value="form.fields.acuidade"
              :disabled="!canEdit"
              @input="el => debounceInput('avbppAcuidadeVisualBinocularParaPerto', el.target.value)"
              type="text"
              class="form-control"
              placeholder="Resultado"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { debounce } from 'lodash'

export default {
  name: 'AvbppAcuidadeVisualBinocularParaPertoForm',
  components: {
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'),
    EyeFill: () => import('@/assets/icons/eye-fill.svg')
  },
  data() {
    return {
      debounceInput: debounce(this.updateForm, 300)
    }
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.avbppAcuidadeVisualBinocularParaPerto
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/avbppAcuidadeVisualBinocularParaPerto', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
      this.updateMedicalRecord(this.form)
    },
    hideForm() {
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      this.form.fields.acuidade = null
    }
  }
}
</script>
